import React, { useState, useEffect } from 'react'
import { Container, setConfiguration } from 'react-grid-system'
import { Divider, Spin } from 'antd'
import { parseString } from 'xml2js'

import Header from '../Header'
import ReviewList from '../ReviewList'
import InfoBar from '../InfoBar'
import FilterBar from '../FilterBar'
import ShareModal from '../ShareModal'

import { appID, regions, ratings, daylines, wordcounts } from '../../utils/config'

import './App.css'

setConfiguration({ 
  defaultScreenClass: 'sm', 
  gridColumns: 2, 
  gutterWidth: 24,
  containerWidths: [560, 720, 720, 720],
  breakpoints: [560, 720, 720, 720]
})

const footer = (
  <footer>
    &copy; {new Date().getFullYear()} Crafted with <span className='sakura'>❤︎</span> by <span className='ocean'>Polaris</span>
  </footer>
)

const cleanURL = url => {
  let cleanedURL = url.replace(/xml\?.*?$/, 'xml')
  return cleanedURL
}

const xml2JSON = xml => {
  let res
  parseString(xml, {}, (err, result) => {
    res = result
  })
  return res
}

const filterData = (data, region, rating, dayline, wordcount) => {
  // Filter region
  let filteredData
  if (Object.keys(data).includes(region)) {
    filteredData = data[region]
  } else {
    const all = [].concat.apply([], Object.values(data))
    filteredData = all.sort((a, b) => (b.date > a.date ? 1 : -1))
  }

  // Filter rating
  if (ratings.includes(rating)) {
    filteredData = filteredData.filter(e => e.rating === rating)
  }

  // Filter dayline
  if (daylines.includes(dayline)) {
    const minDay = new Date(new Date().getTime() - dayline * 24 * 60 * 60 * 1000)
    filteredData = filteredData.filter(e => e.date > minDay)
  }

  // Filter wordcount
  if (wordcounts.includes(wordcount)) {
    filteredData = filteredData.filter(e => e.title.length + e.content.length >= wordcount)
  }

  return filteredData
}

const countData = data => {
  return [].concat.apply([], Object.values(data)).length
}

const NO_FILTER = 'all'

const App = () => {
  const [loaded, setLoaded] = useState(false)
  const [data, setData] = useState([])

  const [sharing, setSharing] = useState(false)
  const [entry, setEntry] = useState(null)

  const [region, setRegion] = useState(NO_FILTER)
  const [rating, setRating] = useState(NO_FILTER)
  const [dayline, setDayline] = useState(NO_FILTER)
  const [wordcount, setWordcount] = useState(NO_FILTER)

  useEffect(() => {
    const fetchData = async (url) => {
      console.log(`FETCHING URL [${url}]`)
      const ret = await fetch(url)
        .then(res => res.text())
        .then(
          async (result) => {
            result = xml2JSON(result)
            if (!result) {
              return []
            }
            const feed = result.feed
            const entries = feed.entry || []
            console.log('FETCHED DATA', entries)
            const urls = feed.link
            const lastURL = cleanURL(urls[3].$.href)
            const nextURL = cleanURL(urls[5].$.href)
            if (url !== lastURL && nextURL.length > 0) {
              const next = await fetchData(nextURL)
              return entries.concat(next)
            } else {
              return entries
            }
          },
          (error) => {
            console.log(error)
            return error
          }
        )
      return ret
    }

    const mapData = (region, data) => {
      return data.map(entry => ({
        id: entry.id[0],
        title: entry.title[0],
        content: entry.content[0]._,
        author: entry.author[0].name[0],
        rating: entry['im:rating'][0],
        version: entry['im:version'][0],
        date: new Date(entry.updated[0]),
        region: regions[region]
      }))
    }

    const fetchAllData = async () => {
      let all = {}
      for (const region of Object.keys(regions)) {
        const url = `https://itunes.apple.com/${region}/rss/customerreviews/page=1/id=${appID}/sortby=mostrecent/xml`
        let result = await fetchData(url)
        result = result.filter(e => e)
        all[region] = mapData(region, result)
      }
      console.log('FETCHED DATA', all)
      setData(all)
      setLoaded(true)
    }

    fetchAllData()
  }, [])

  const handleShare = entry => {
    const top = `-${window.scrollY}px`
    document.body.style.position = 'fixed'
    document.body.style.top = top
    
    setSharing(true)
    setEntry(entry)
  }

  const handleShareCancel = () => {
    const top = document.body.style.top
    document.body.style.position = ''
    document.body.style.top = ''
    window.scrollTo(0, parseInt(top || '0') * -1)

    setSharing(false)
    setEntry(null)
  }

  const handleRegionChange = region => {
    setRegion(region)
  }

  const handleRatingChange = rating => {
    setRating(rating)
  }

  const handleDaylineChange = dayline => {
    setDayline(dayline)
  }

  const handleWordcountChange = wordcount => {
    setWordcount(wordcount)
  }

  return (
    <Container className='App'>
      <Header />
      <div className='card'>
        <InfoBar count={countData(data)} />
        <Divider style={{ margin: '12px 0' }} />
        <FilterBar
          region={region} onRegionChange={handleRegionChange}
          rating={rating} onRatingChange={handleRatingChange}
          dayline={dayline} onDaylineChange={handleDaylineChange}
          wordcount={wordcount} onWordcountChange={handleWordcountChange}
        />
      </div>
      {loaded ? (
        <ReviewList data={filterData(data, region, rating, dayline, wordcount)} onShare={handleShare} />
      ) : (
        <div className='card'>
          <Spin />
        </div>
      )}
      {footer}
      <ShareModal visible={sharing} onCancel={handleShareCancel} entry={entry} />
    </Container>
  )
}

export default App
