import React from 'react'

import { appFullName, appIcon, appID } from '../../utils/config'

import './InfoBar.css'

const InfoBar = ({ count }) => {
  return (
    <div className='InfoBar'>
      <div className='InfoBar-icon'>
        <img src={appIcon} alt='app icon' />
      </div>
      <div className='InfoBar-info'>
        <h2>{appFullName}</h2>
        <p>ID: {appID} | {count} Reviews</p>
      </div>
    </div>
  )
}

export default InfoBar
