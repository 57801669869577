import React from 'react'

import './Header.css'

const Header = () => {
  return (
    <header className='Header'>
      <h1><b>Fab</b>Review</h1>
    </header>
  )
}

export default Header
