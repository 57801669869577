import React, { useState, useEffect, useRef } from 'react'
import html2canvas from 'html2canvas'
import { Modal } from 'antd'

import ReviewCard from '../ReviewCard'

import './ShareModal.css'

const ShareModal = ({ visible, onCancel, entry }) => {
  const [img, setImg] = useState(null)
  const reviewNode = useRef(null)
  
  useEffect(() => {
    console.log('RENDERING IMAGE', entry, reviewNode.current)
    if (entry) {
      setTimeout(() => {
        html2canvas(reviewNode.current, { height: 640, width: 640 }).then(canvas => {
          setImg(canvas.toDataURL('image/png'))
        })
      }, 400)
    }
  }, [entry])

  const handleCancel = () => {
    onCancel()
    setImg(null)
  }

  return (
    <Modal
      wrapClassName='ShareModal'
      centered
      visible={visible}
      onCancel={handleCancel}
      footer={null}
    >
      <div className='ShareModal-stage' ref={reviewNode}>
        {img ? (
          <img src={img} alt='review' />
        ) : (
          entry ? <ReviewCard entry={entry} /> : <div></div>
        )}
      </div>
      <p className='ShareModal-footer'>HOLD IMAGE TO SAVE & SHARE</p>
    </Modal>
  )
}

export default ShareModal
