import React from 'react'

import { ratingStars, localDateString } from '../../utils/mapper'
import { appFullName } from '../../utils/config'

import appIcon from '../../assets/app-icon.png'

import './ReviewCard.css'

const Divider = () => {
  return (
    <span className='Divider'> | </span>
  )
}

const ReviewCard = ({ entry }) => {
  const { title, content, author, rating, version, date, region } = entry

  return (
    <div className='ReviewCard card'>
      <div className='ReviewCard-icon'>
        <img src={appIcon} alt='app icon' />
      </div>
      <h1 className='ReviewCard-name'>{appFullName}</h1>
      <h3 className='ReviewCard-title'>{title}</h3>
      <div className='ReviewCard-rating'>{ratingStars(rating)}</div>
      <p className='ReviewCard-content'>{content}</p>
      <div className='ReviewCard-info'>
        {author}
        <Divider />
        {localDateString(date)}
        <Divider />
        {region}
        <Divider />
        v{version}
      </div>
      <div className='ReviewCard-copyright'>Powered by FabReview, a Polaris Chen work.</div>
    </div>
  )
}

export default ReviewCard
