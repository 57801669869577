const config = {
  appName: '时间胶囊',
  appSlogan: '遇见未来的自己',
  appIcon: 'https://is2-ssl.mzstatic.com/image/thumb/Purple124/v4/5e/86/d5/5e86d5b8-47fe-1366-7000-84e805848260/AppIcon-0-0-1x_U007emarketing-0-0-0-10-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/246x0w.png',
  appID: '1195852313',
  regions: {
    cn: 'China',
    hk: 'Hong Kong',
    tw: 'Taiwan',
    us: 'United States',
    jp: 'Japan'
  },
  ratings: ['1', '2', '3', '4', '5'],
  daylines: ['3', '7', '30', '365'],
  wordcounts: ['200', '100', '50', '20']
}

export const { appName, appSlogan, appIcon, appID, regions, ratings, daylines, wordcounts } = config

export const appFullName = `${appName} - ${appSlogan}`
