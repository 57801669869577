import React from 'react'
import { RiMapPinUserLine, RiMapPinTimeLine, RiMapPinLine, RiMapPin2Line } from 'react-icons/ri'

import { ratingStars, localDateString } from '../../utils/mapper'

import './ReviewItem.css'

const ReviewItem = ({ entry, onShare }) => {
  const { title, content, author, rating, version, date, region } = entry

  const handleShare = () => {
    onShare(entry)
  }

  return (
    <div className='ReviewItem card' onClick={handleShare} title='SHARE'>
      <div className="ReviewItem-text">
        <h3 className="ReviewItem-title">{title}</h3>
        <div className="ReviewItem-rating">{ratingStars(rating)}</div>
        <p className="ReviewItem-content">{content}</p>
        <div className="ReviewItem-info"><RiMapPinUserLine />{author} <RiMapPinTimeLine />{localDateString(date)} <RiMapPinLine />{region} <RiMapPin2Line />v{version}</div>
      </div>
    </div>
  )
}

export default ReviewItem
