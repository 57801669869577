export const ratingStars = rating => {
  const start = 5 - rating
  return '★★★★★☆☆☆☆☆'.slice(start, start + 5)
}

// eslint-disable-next-line no-unused-vars
const dateString = (timestamp, timeZone) => {
  const date = new Date(parseInt(timestamp))
  let options = {
    year: 'numeric', month: '2-digit', day: '2-digit',
    hour: '2-digit', minute: '2-digit', second: '2-digit',
    hour12: false,
  }
  options.timeZone = timeZone
  return date.toLocaleString('zh-CN', options).replace(/\//g, '.')
}

const simpleDateString = (timestamp, timeZone) => {
  const date = new Date(parseInt(timestamp))
  let options = {
    year: 'numeric', month: 'short', day: 'numeric'
  }
  options.timeZone = timeZone
  return date.toLocaleDateString('en-US', options)
}

export const localDateString = date => {
  return simpleDateString(date.getTime(), 'Asia/Shanghai')
}
