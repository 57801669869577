import React from 'react'
import { List } from 'antd'

import ReviewItem from '../ReviewItem'

import './ReviewList.css'

const paginationConfig = {
  position: 'bottom',
  pageSize: 10,
  simple: false,
  showSizeChanger: false
}

const ReviewList = ({ data, onShare }) => {
  console.log('RENDERING DATA', data)
  return (
    <div className='ReviewList'>
      <List
        className='ReviewList-content'
        dataSource={data}
        renderItem={d => <ReviewItem key={d.id} entry={d} onShare={onShare} />}
        pagination={paginationConfig}
      />
    </div>
  )
}

export default ReviewList
