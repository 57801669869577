import React from 'react'
import { Radio } from 'antd'

import { regions } from '../../utils/config'

import './FilterBar.css'

const FilterBar = (props) => {
  const { 
    region, onRegionChange, 
    rating, onRatingChange,
    dayline, onDaylineChange,
    wordcount, onWordcountChange
  } = props

  const handleRegionChange = e => {
    onRegionChange(e.target.value)
  }

  const handleRatingChange = e => {
    onRatingChange(e.target.value)
  }

  const handleDaylineChange = e => {
    onDaylineChange(e.target.value)
  }

  const handleWordcountChange = e => {
    onWordcountChange(e.target.value)
  }

  return (
    <div className='FilterBar'>

      <Radio.Group className='FilterBar-Row' onChange={handleRegionChange} value={region}>
        <Radio.Button value='all'>All</Radio.Button>
        {Object.keys(regions).map(k => (<Radio.Button value={k} key={k}>{k.toUpperCase()}</Radio.Button>))}
      </Radio.Group>

      <Radio.Group className='FilterBar-Row' onChange={handleDaylineChange} value={dayline}>
        <Radio.Button value='all'>All</Radio.Button>
        <Radio.Button value='3'>3D</Radio.Button>
        <Radio.Button value='7'>1W</Radio.Button>
        <Radio.Button value='30'>1M</Radio.Button>
        <Radio.Button value='365'>1Y</Radio.Button>
      </Radio.Group>
      
      <Radio.Group className='FilterBar-Row' onChange={handleRatingChange} value={rating}>
        <Radio.Button value='all'>All</Radio.Button>
        <Radio.Button value='5'>5★</Radio.Button>
        <Radio.Button value='4'>4★</Radio.Button>
        <Radio.Button value='3'>3★</Radio.Button>
        <Radio.Button value='2'>2★</Radio.Button>
        <Radio.Button value='1'>1★</Radio.Button>
      </Radio.Group>

      <Radio.Group className='FilterBar-Row' onChange={handleWordcountChange} value={wordcount}>
        <Radio.Button value='all'>All</Radio.Button>
        <Radio.Button value='200'>200+</Radio.Button>
        <Radio.Button value='100'>100+</Radio.Button>
        <Radio.Button value='50'>50+</Radio.Button>
        <Radio.Button value='20'>20+</Radio.Button>
      </Radio.Group>

    </div>
  )
}

export default FilterBar
